import React, { useState } from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { getArticlePath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink, OutboundLink } from "./link"
import { render } from "datocms-structured-text-to-plain-text"
import BlockParallax from "./blockParallax"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"

const ArticleThumb = ({ article, small, index = null }) => {
  const [showIcon, setShowIcon] = useState()
  const breakpointIndex = useBreakpointIndex()

  return (
    <Box
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      sx={{
        overflow: "hidden",
        height: "100%",
      }}
    >
      <InboundLink
        to={getArticlePath(article, article.locale)}
        sx={{ textDecoration: "none" }}
      >
        <Grid
          gap={[0, 0]}
          sx={{
            gridTemplateRows: "minmax(200px,400px) .5fr",
            minHeight: "450px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "relative",
              ".gatsby-image-wrapper": {
                height: "100%",
              },
            }}
          >
            {console.log("article",article)}
              <GatsbyImage
                image={breakpointIndex > 1 ? article.heroImage.desktop : article.heroImage.mobile}
                alt={article.heroImage.desktop}
              />
          </Box>
          <Flex
            sx={{
              py:[2],
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                pt:[1],
                pb:[2],
                position: "relative",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: ["block", "space-between"],
              }}
            >
              <Box>
                <Heading
                  sx={{
                    fontSize: [3],
                    color: "primary",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  {article.meta.firstPublishedAt}
                </Heading>
              </Box>
            </Box>
            <Heading as="h2" variant="h3" sx={{ mb: 3, mt: 0, color: "text" }}>
              {article.title}
            </Heading>
            {/* <Text
              dangerouslySetInnerHTML={{ __html: article.subtitle }}
              as="div"
              variant="p"
              color={"dark"}
              sx={{
                p: { m: 0 },
              }}
            />
            <Text as="p" sx={{ fontSize: 1, color: "dark" }}>
              {render(article.body)
                ? render(article.body).substring(0, 120) + " ..."
                : ""}
            </Text> */}
            
          </Flex>
        </Grid>
      </InboundLink>
    </Box>
  )
}

export default ArticleThumb
